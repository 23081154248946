<template>
    <div class="mt-5">
        <div class="control has-top-border">
            <div class="is-flex">
                <h1 class="title">Users</h1>
                <font-awesome-icon class="collapsable-icon ml-3 mt-1" :icon="determineIcon()" @click="isCollapsed = !isCollapsed" />
            </div>
        </div>
        <div v-if="!isCollapsed">
            <Loading v-if="isLoading" />
            <div v-else-if="userListEmpty">
                This account has no users.
            </div>
            <div v-else>
                <div class="field">
                    <router-link to="/users">
                        <button class="button is-primary" @click="setAccountPublicId">
                            Go to Full User List
                        </button>
                    </router-link>
                </div>
                <div class="table-container">
                    <table class="table is-striped is-fullwidth has-top-border has-bottom-border">
                        <thead>
                            <tr>
                                <th><abbr title="Public Id">PId</abbr></th>
                                <th>Email</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Role</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(user, index) in accountUsers" :key="'user' + index">
                                <td>
                                    <router-link :to="'/user/' + user.publicId">{{ user.publicId }}</router-link>
                                </td>
                                <td> {{ user.email }} </td>
                                <td> {{ user.firstName }} </td>
                                <td> {{ user.lastName }} </td>
                                <td> {{ roleDictionary[user.role] }}</td>
                                <td> {{ statusDictionary[user.status] }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Pagination :isLoading="isLoading" :currentPage="currentPage" :totalPages="totalPages" v-on:setPage="n => setPage(n)" />
            </div>
        </div>
    </div>
</template>

<script>
    import { securePost } from '../secureFetch.js';

    import Loading from "./Loading.vue"
    import Pagination from '../components/Pagination';

    export default {
        name: "AccountUsers",
        components: {
            Loading,
            Pagination
        },
        props: {
            accountPublicId: {
                type: String,
                default: null
            },
            isCollapsed: {
                type: Boolean,
                default: true
            },
        },
        watch: {
            accountPublicId(value) {
                if (value) {
                    this.loadAccountUsers = true;
                }           
            },
            errorMessage(value) {
                this.$emit("error", value);
            },
            loadAccountUsers(value) {
                if (value) {
                    this.getAccountUsers();
                }
            }
        },
        mounted() {
            if (this.accountPublicId) {
                this.loadAccountUsers = true;
            }
        },
        computed: {
            hostUrl: function () {
                return this.$store.state.hostUrl;
            },
            currentPage: {
                set(value) {
                    this.$store.commit('updateUserPageFilter', value);
                },
                get() {
                    return this.$store.state.userFilters.page;
                }
            },
        },
        data() {
            return {
                isLoading: true,
                userListEmpty: false,
                accountUsers: [],
                totalPages: 0,
                resultsPerPage: 10,
                errorMessage: null,
                roleDictionary: { 1: 'Standard', 2: 'Admin', 3: 'Integration', 4: 'Support' },
                statusDictionary: { 1: 'Active', 2: 'Deleted', 3: 'Pending', 4: 'Inactive' },
                defaultErrorMessage: "Something didn't work.",
                loadAccountUsers: false,
            }
        },
        methods: {
            setAccountPublicId() {
                this.$store.commit('updateUserAccountIdFilter', this.accountPublicId);
            },
            getAccountUsers() {
                this.isLoading = true;
                this.totalPages = 0;
                var body = {
                    accountPublicId: this.accountPublicId,
                    page: this.currentPage,
                    resultsPerPage: this.resultsPerPage,
                    searchTerm: "",
                    userRole: 0,
                    userStatus: 0
                };
                securePost(this.hostUrl + "v1/user/search-users", body)
                    .then((data) => {
                        if (data && data.success) {
                            this.accountUsers = data.users;
                            this.currentPage = data.currentPage;
                            this.totalPages = data.totalPages;
                            this.userListEmpty = !data.users.length;
                        } else {
                            this.errorMessage = data.message;
                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                        this.isLoading = false;
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            setPage(page) {
                this.currentPage = page;
                this.getAccountUsers();
            },
            determineIcon() {
                if (this.isCollapsed) {
                    return ['fa', 'angle-up'];
                }
                return ['fa', 'angle-down'];
            },
        }
    }
</script>

<style scoped>
    .has-bottom-border {
        border-bottom: 1px solid #808080;
        padding-bottom: 1em;
    }

    .feature-list:first-child {
        border-top: 1px solid #808080;
        padding-top: 1em;
    }

    .has-background-alt {
        background-color: #141514;
        margin-top: -1.425em;
    }

    .collapsable-icon {
        height: 32px;
        width: 32px;
    }

    .template-button {
        min-width: 25em;
    }
</style>